import storage from "../../api/api";

export const state = {
  contract: {
    types: [],
    statuses: [],
    payoutPeriods: [],
    externalTenants: [],
    paymentMethods: [],
  },
  counterpart: {
    types: [],
    legalForms: [],
    documentTypes: [],
    states: [],
    statuses: [],
    crimeRiskLevels: [],
    identificationStatuses: [],
    activityEvaluations: [],
    stateEvaluations: [],
    taxSystems: [],
    activities: [],
    propertyForms: [],
    publicityTypes: [],
    positions: [],
    verificationMethods: [],
  },
  operation: {
    nbuPaysystemList: [],
    destinationCodeList: [],
    countryList: [],
  },
  financialModelsQuestionsAnswers: [],
  financialModelsCodes: [],
  financialModelsTypes: [],
  h020: [],
  nbuFileStatuses: [],
  task: {
    types: [],
  },
};

export const getters = {
  allContractTypes: (state) => state.contract.types,
  allContractStatuses: (state) => state.contract.statuses,
  allContractPayoutPeriods: (state) => state.contract.payoutPeriods,
  allContractExternalTenants: (state) => state.contract.externalTenants,
  allContractPaymentMethods: (state) => state.contract.paymentMethods,
  allCounterpartTypes: (state) => state.counterpart.types,
  allCounterpartLegalForms: (state) => state.counterpart.legalForms,
  allCounterpartDocumentTypes: (state) => state.counterpart.documentTypes,
  allCounterpartStates: (state) => state.counterpart.states,
  allCounterpartStatuses: (state) => state.counterpart.statuses,
  allCounterpartCrimeRiskLevels: (state) => state.counterpart.crimeRiskLevels,
  allCounterpartIdentificationStatuses: (state) =>
    state.counterpart.identificationStatuses,
  allCounterpartActivityEvaluations: (state) =>
    state.counterpart.activityEvaluations,
  allCounterpartStateEvaluations: (state) => state.counterpart.stateEvaluations,
  allCounterpartTaxSystems: (state) => state.counterpart.taxSystems,
  allCounterpartActivities: (state) => state.counterpart.activities,
  allCounterpartPropertyForms: (state) => state.counterpart.propertyForms,
  allCounterpartVerificationMethods: (state) =>
    state.counterpart.verificationMethods,
  allOperationDestinationCode: (state) => state.operation.destinationCodeList,
  allCountries: (state) => state.operation.countryList,
  allOperationNbuPaysystemList: (state) => state.operation.nbuPaysystemList,
  allFinancialModelsQuestionsAnswers: (state) =>
    state.financialModelsQuestionsAnswers,
  allFinancialModelsCodes: (state) => state.financialModelsCodes,
  allFinancialModelsTypes: (state) => state.financialModelsTypes,
  allH020: (state) => state.h020,
  allPublicityTypes: (state) => state.counterpart.publicityTypes,
  allTaskTypes: (state) => state.task.types,
  allCounterpartPositions: (state) => state.counterpart.positions,
  allNbuFileStatuses: (state) => state.nbuFileStatuses,
};

function pushDictionariesByType(data, commit) {
  data.forEach((item) => {
    const type = item.type
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("");
    if (this._mutations["dictionary/push" + type]) {
      commit("push" + type, item);
    }
  });
}

export const actions = {
  async pullContractDictionaries({ commit }) {
    commit("flushContractTypes");
    commit("flushContractStatuses");
    commit("flushContractPayoutPeriods");
    commit("flushContractPaymentMethods");
    await storage.getDictionary("contract-dictionaries").then(({ data }) => {
      pushDictionariesByType.call(this, data, commit);
    });
  },
  async pullCounterpartDictionaries({ commit }) {
    commit("flushCounterpartTypes");
    commit("flushCounterpartLegalForms");
    commit("flushCounterpartDocumentTypes");
    commit("flushCounterpartStates");
    commit("flushCounterpartStatuses");
    commit("flushCounterpartCrimeRiskLevels");
    commit("flushCounterpartIdentificationStatuses");
    commit("flushCounterpartActivityEvaluations");
    commit("flushCounterpartStateEvaluations");
    commit("flushCounterpartTaxSystems");
    commit("flushCounterpartActivities");
    commit("flushCounterpartPropertyForms");
    commit("flushCounterpartPublicityTypes");
    commit("flushCounterpartPositions");
    commit("flushCounterpartVerificationMethods");
    await storage.getDictionary("counterpart-dictionaries").then(({ data }) => {
      pushDictionariesByType.call(this, data, commit);
    });
  },
  async pullOperationDestinationCode({ commit }) {
    commit("flushOperationDestinationCode");
    await storage
      .getDictionary("operation-destination-code")
      .then(({ data }) => {
        data.forEach((item) => commit("pushOperationDestinationCode", item));
      });
  },
  async pullOperationNbuPaysystemList({ commit }) {
    commit("flushOperationNbuPaysystemList");
    await storage.getDictionary("operation-nbu-paysystems").then(({ data }) => {
      data.forEach(function (item) {
        commit("pushOperationNbuPaysystem", item);
      });
    });
  },
  async pullTaskTypes({ commit }) {
    commit("flushTaskTypes");
    await storage.getDictionary("task-types").then(({ data }) => {
      data.forEach((item) => commit("pushTaskType", item));
    });
  },
  async pullFinancialModelsQuestionsAnswers({ commit }) {
    commit("flushFinancialModelsQuestionsAnswers");
    await storage
      .getDictionary("financial-models-qa-risks")
      .then(({ data }) => {
        data.forEach(function (item) {
          commit("pushFinancialModelsQuestionsAnswers", item);
        });
      });
  },
  async pullSpfmCodes({ commit }) {
    commit("flushSpfmCodes");
    await storage.getDictionary("financial-model-codes").then(({ data }) => {
      data.forEach((item) => commit("pushSpfmCodes", item));
    });
  },
  async pullSpfmTypes({ commit }) {
    commit("flushSpfmTypes");
    await storage
      .getDictionary("dsfm-person-legal-statuses")
      .then(({ data }) => {
        data.forEach((item) => commit("pushSpfmTypes", item));
      });
  },
  async pullH020({ commit }) {
    commit("flushH020");
    await storage.getDictionary("financial-model-h020").then(({ data }) => {
      data.forEach((item) => commit("pushH020", item));
    });
  },
  async pullCountries({ commit }) {
    commit("flushCountries");
    await storage.getDictionary("countries").then(({ data }) => {
      data.forEach((item) => commit("pushCountries", item));
    });
  },
  async pullContractExternalTenants({ commit }) {
    commit("flushContractExternalTenants");
    await storage
      .getDictionary("contract-external-tenants")
      .then(({ data }) => {
        data.forEach((item) => commit("pushContractExternalTenants", item));
      });
  },
  async pullNbuFileStatuses({ commit }) {
    commit("flushNbuFileStatuses");
    await storage.getDictionary("nbu-file-statuses").then(({ data }) => {
      data.forEach((item) => commit("pushNbuFileStatuses", item));
    });
  },
};

export const mutations = {
  flushContractTypes: (state) => (state.contract.types = []),
  pushContractTypes: (state, item) => state.contract.types.push(item),
  flushContractStatuses: (state) => (state.contract.statuses = []),
  pushContractStatuses: (state, item) => state.contract.statuses.push(item),
  flushContractPayoutPeriods: (state) => (state.contract.payoutPeriods = []),
  pushContractPayoutPeriods: (state, item) =>
    state.contract.payoutPeriods.push(item),
  flushContractExternalTenants: (state) =>
    (state.contract.externalTenants = []),
  pushContractExternalTenants: (state, item) =>
    state.contract.externalTenants.push(item),
  flushContractPaymentMethods: (state) => (state.contract.paymentMethods = []),
  pushContractPaymentMethods: (state, item) =>
    state.contract.paymentMethods.push(item),
  flushCounterpartTypes: (state) => (state.counterpart.types = []),
  pushCounterpartTypes: (state, item) => state.counterpart.types.push(item),
  flushCounterpartLegalForms: (state) => (state.counterpart.legalForms = []),
  pushCounterpartLegalForms: (state, item) =>
    state.counterpart.legalForms.push(item),
  flushCounterpartDocumentTypes: (state) =>
    (state.counterpart.documentTypes = []),
  pushCounterpartDocumentTypes: (state, item) =>
    state.counterpart.documentTypes.push(item),
  flushCounterpartStates: (state) => (state.counterpart.states = []),
  pushCounterpartStates: (state, item) => state.counterpart.states.push(item),
  flushCounterpartStatuses: (state) => (state.counterpart.statuses = []),
  pushCounterpartStatuses: (state, item) =>
    state.counterpart.statuses.push(item),
  flushCounterpartCrimeRiskLevels: (state) =>
    (state.counterpart.crimeRiskLevels = []),
  pushCounterpartCrimeRiskLevels: (state, item) =>
    state.counterpart.crimeRiskLevels.push(item),
  flushCounterpartIdentificationStatuses: (state) =>
    (state.counterpart.identificationStatuses = []),
  pushCounterpartIdentificationStatuses: (state, item) =>
    state.counterpart.identificationStatuses.push(item),
  flushCounterpartActivityEvaluations: (state) =>
    (state.counterpart.activityEvaluations = []),
  pushCounterpartEvaluationOptions: (state, item) =>
    state.counterpart.activityEvaluations.push(item),
  flushCounterpartStateEvaluations: (state) =>
    (state.counterpart.stateEvaluations = []),
  pushCounterpartEvaluationOptionStates: (state, item) =>
    state.counterpart.stateEvaluations.push(item),
  flushCounterpartTaxSystems: (state) => (state.counterpart.taxSystems = []),
  pushCounterpartTaxSystems: (state, item) =>
    state.counterpart.taxSystems.push(item),
  flushCounterpartActivities: (state) => (state.counterpart.activities = []),
  pushCounterpartActivity: (state, item) =>
    state.counterpart.activities.push(item),
  flushCounterpartPropertyForms: (state) =>
    (state.counterpart.propertyForms = []),
  pushCounterpartPropertyForms: (state, item) =>
    state.counterpart.propertyForms.push(item),
  flushOperationDestinationCode: (state) =>
    (state.operation.destinationCodeList = []),
  pushOperationDestinationCode: (state, item) =>
    state.operation.destinationCodeList.push(item),
  flushOperationNbuPaysystemList: (state) =>
    (state.operation.nbuPaysystemList = []),
  pushOperationNbuPaysystem: (state, item) =>
    state.operation.nbuPaysystemList.push(item),
  flushCounterpartPublicityTypes: (state) =>
    (state.counterpart.publicityTypes = []),
  pushClarificationPublicityTypes: (state, item) =>
    state.counterpart.publicityTypes.push(item),
  flushCounterpartPositions: (state) => (state.counterpart.positions = []),
  pushCounterpartPositions: (state, item) =>
    state.counterpart.positions.push(item),
  flushCounterpartVerificationMethods: (state) =>
    (state.counterpart.verificationMethods = []),
  pushCounterpartVerificationMethods: (state, item) =>
    state.counterpart.verificationMethods.push(item),
  flushTaskTypes: (state) => (state.task.types = []),
  pushTaskType: (state, item) => state.task.types.push(item),
  flushFinancialModelsQuestionsAnswers: (state) =>
    (state.financialModelsQuestionsAnswers = []),
  pushFinancialModelsQuestionsAnswers: (state, item) =>
    state.financialModelsQuestionsAnswers.push(item),
  flushSpfmCodes: (state) => (state.financialModelsCodes = []),
  pushSpfmCodes: (state, item) => state.financialModelsCodes.push(item),
  flushSpfmTypes: (state) => (state.financialModelsTypes = []),
  pushSpfmTypes: (state, item) => state.financialModelsTypes.push(item),
  flushH020: (state) => (state.h020 = []),
  pushH020: (state, item) => state.h020.push(item),
  flushCountries: (state) => (state.operation.countryList = []),
  pushCountries: (state, item) => state.operation.countryList.push(item),
  flushNbuFileStatuses: (state) => (state.nbuFileStatuses = []),
  pushNbuFileStatuses: (state, item) => state.nbuFileStatuses.push(item),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
